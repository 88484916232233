import styles from "./notfound.module.scss";
const NotFound = () => {
  return (
    <section className={`${styles.wrapper} section section--code`}>
      <h1 data-aos="zoom-in" data-aos-duration="800">
        404
      </h1>
    </section>
  );
};

export default NotFound;
