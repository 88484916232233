import SocialMediaIcons from "components/SocialMedia";
const Footer = () => {
  return (
    <footer className="site_footer">
      <SocialMediaIcons />
    </footer>
  );
};

export default Footer;
