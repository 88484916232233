const ambienceFiles = [
  "/Audios/Ambience_03.0.ogg",
  "/Audios/Ambience_03.1.ogg",
  "/Audios/Ambience_03.2.ogg",
  "/Audios/Ambience_03.3.ogg",
  "/Audios/Ambience_03.4.ogg",
  "/Audios/Ambience_03.5.ogg",
  "/Audios/Ambience_03.6.ogg",
  "/Audios/Ambience_03.7.ogg",
  "/Audios/Ambience_03.8.ogg",
];

let currentAmbienceIndex = 0;
let currentAmbience = null;

const nextAmbience = () => {
  alert("new ambience");
  currentAmbienceIndex++;
  currentAmbience = new Audio(ambienceFiles[currentAmbienceIndex]);
  currentAmbience.play();
  currentAmbience.addEventListener("ended", nextAmbience);
};

const playAmbience = () => {
  if (!currentAmbience) {
    currentAmbience = new Audio(ambienceFiles[currentAmbienceIndex]);
  }
  currentAmbience.paused ? currentAmbience.play() : currentAmbience.pause();
  currentAmbience.addEventListener("ended", nextAmbience);
};
const clickEventsForAudioAmbience = () => {
  const ambienceLinks = document.querySelectorAll(".audio-ambience-link");
  [...ambienceLinks].forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      playAmbience();
    });
  });
};

const hookAudioEvents = () => {
  const audioLinks = document.querySelectorAll(".audio-link");

  let hoverFile = null;
  let clickFile = null;
  let swishFile = null;

  [...audioLinks].forEach((audioLink) => {
    audioLink.addEventListener("mouseenter", () => {
      hoverFile = new Audio("/Audios/Hover_01.ogg");
      hoverFile.play();
    });

    audioLink.addEventListener("click", () => {
      hoverFile.pause();
      clickFile = new Audio("/Audios/Click_01.ogg");
      clickFile.play();
      swishFile = new Audio("/Audios/PlanetOn_01.ogg");
      swishFile.play();
    });
  });
};

export { hookAudioEvents, clickEventsForAudioAmbience, playAmbience };
