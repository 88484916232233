import { useState, useEffect } from "react";
import throttle from "lodash.throttle";
export const useScroll = (offset = 0) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = throttle(() => {
      const { scrollTop } = document.documentElement;
      const scrolled = scrollTop > offset;
      setHasScrolled(scrolled);
    }, 200);

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasScrolled;
};

export const useScrolledPast = (cls: string) => {
  const [hasScrolledPast, setHasScrolledPast] = useState(false);
  const isAtBottom = (y: number, originalOffset: number) => {
    return y >= originalOffset;
  };

  useEffect(() => {
    const el = document.querySelector(cls);
    if (!el) {
      return;
    }
    const { top } = el.getBoundingClientRect();
    const offsetAtOrigin = top + el.clientHeight;

    const handleScroll = throttle(() => {
      const hasReached = isAtBottom(window.scrollY, offsetAtOrigin);
      setHasScrolledPast(hasReached);
    }, 250);

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasScrolledPast;
};
