/* eslint-disable no-unused-vars */
import "./scss/main.scss"
import AOS from "aos"
import "aos/dist/aos.css" // You can also use <link> for styles
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { useEffect } from "react"
import { AudioContext } from "audio/context"
import { MenuContext } from "menu/context"

import MainLayout from "components/layouts/MainLayout.jsx"

import HomePage from "pages/Home"
import NotFound from "pages/NotFound"
import Header from "components/Header"
import Footer from "components/Footer"

import IntroSection from "components/IntroSection.jsx"
import { hookAudioEvents } from "utils/audioHandler"

import Cursor from "utils/cursor"

function App() {
  const isNotFoundPage = window.location.pathname !== "/"
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-back"
    })

    hookAudioEvents()

    const cursor = new Cursor(document.querySelector(".cursor"))
    ;[
      ...document.querySelectorAll("a, button, input:not(input[type='text'])")
    ].forEach((el) => {
      el.addEventListener("mouseenter", () => cursor.emit("enter"))
      el.addEventListener("mouseleave", () => cursor.emit("leave"))
    })
  }, [])

  return (
    <>
      <AudioContext>
        <MenuContext>
          <BrowserRouter>
            <MainLayout>
              <div className="wrapper">
                <Switch>
                  <Route exact path="/">
                    <Header />
                    {/* <IntroSection /> */}
                    {/* <HomePage /> */}
                  </Route>
                  {/* <Route path="/audio">
                <AudioPage />
              </Route>
              <Route path="/video">
                <VideoPage />
              </Route>
              <Route path="/demo">
                <DemoPage />
              </Route> */}
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </div>
              {!isNotFoundPage && <Footer />}
            </MainLayout>
          </BrowserRouter>
        </MenuContext>
      </AudioContext>

      <svg className="cursor" width="220" height="220" viewBox="0 0 220 220">
        <defs>
          <filter
            id="filter-1"
            x="-50%"
            y="-50%"
            width="200%"
            height="200%"
            filterUnits="objectBoundingBox"
          >
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0"
              numOctaves="1"
              result="warp"
            />
            <feOffset dx="-30" result="warpOffset" />
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="30"
              in="SourceGraphic"
              in2="warpOffset"
            />
          </filter>
        </defs>
        <circle className="cursor__inner" cx="110" cy="110" r="60" />
      </svg>

      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </>
  )
}

export default App
