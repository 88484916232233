/* eslint-disable no-unused-vars */
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import GroundBreakIcon from "components/Icons/Groundbreak"

import { useAudio } from "audio/context"
const getPathInfo = (pathname) => {
  const APP_NAME = "Groundbreak"
  const getPath = (pathname) => {
    return pathname.split("").slice(1).join("")
  }
  const p = getPath(pathname)
  const getIngress = (path) => {
    switch (path) {
      case "demo":
        return ""
      default:
        return "Consulting & Agency"
    }
  }
  return {
    title: p || APP_NAME,
    ingress: getIngress(p),
    background: ""
  }
}
const Header = ({ currentPath }) => {
  const { unsetIsMuted } = useAudio()
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0]
    if (window.scrollY === 0) {
      body.classList = "no-scroll"
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    document.body.classList = ""
    const targetElement = document.getElementById("introsection")
    let headerOffset = 10
    let elementPosition = targetElement.getBoundingClientRect().top
    let offsetPosition = elementPosition - headerOffset

    window.scrollTo({
      left: 0,
      top: offsetPosition,
      behavior: "smooth"
    })

    unsetIsMuted()
  }

  const location = useLocation()
  const pathInfo = getPathInfo(location.pathname)
  return (
    <header className="hero">
      <div className="hero-center">
        <GroundBreakIcon data-aos="zoom-in" />
        {/* <h1 className="hero__logo" data-aos="zoom-in">
          {pathInfo.title.toLocaleUpperCase()}
        </h1> */}
        <h2
          className="hero__text"
          data-aos="fade-up"
          data-aos-easing="ease"
          data-aos-delay="400"
        >
          {pathInfo.ingress}
        </h2>
      </div>

      <button
        type="button"
        className="hero__scroll audio-ambience-link"
        style={{ background: "transparent", border: "none", color: "#fff" }}
        data-aos="fade-up"
        data-aos-easing="ease"
        data-aos-delay="800"
      >
        <img
          style={{ width: "100%" }}
          alt="kontakta oss"
          src="/groundbreak-email.svg"
        />
      </button>
      {/* <button
        className="hero__scroll audio-ambience-link"
        style={{ background: "transparent", border: "none", color: "#fff" }}
        onClick={handleClick}
        data-aos="fade-up"
        data-aos-easing="ease"
        data-aos-delay="800"
      >
        Step in <br />
        <i className="chevron bottom"></i>
      </button> */}
    </header>
  )
}

export default Header
