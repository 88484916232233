import React, { FC, useMemo } from "react";

export interface AudioState {
  isMuted: boolean;
  ambience: HTMLAudioElement;
  ambienceFiles: Array<string>;
}

const initialState = {
  isMuted: true,
  ambience: null,
  ambienceFiles: [
    "/Audios2/Audiio_SoftRiser4.wav",
    "/Audios2/bg.ogg",
    "",
    // "/Audios/Ambience_03.0.ogg",
    // "/Audios/Ambience_03.1.ogg",
    // "/Audios/Ambience_03.2.ogg",
    // "/Audios/Ambience_03.3.ogg",
    // "/Audios/Ambience_03.4.ogg",
    // "/Audios/Ambience_03.5.ogg",
    // "/Audios/Ambience_03.6.ogg",
    // "/Audios/Ambience_03.7.ogg",
    // "/Audios/Ambience_03.8.ogg",
  ],
};

type Action =
  | {
      type: "SET_IS_MUTED";
    }
  | {
      type: "UNSET_IS_MUTED";
    }
  | {
      type: "SET_AMBIENCE";
      value: any;
    };

export const UIContext = React.createContext<AudioState | any>(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: AudioState, action: Action) {
  switch (action.type) {
    case "SET_IS_MUTED": {
      return {
        ...state,
        isMuted: true,
      };
    }
    case "SET_AMBIENCE": {
      return {
        ...state,
        ambience: action.value,
      };
    }
    case "UNSET_IS_MUTED": {
      return {
        ...state,
        isMuted: false,
      };
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const setIsMuted = () => {
    dispatch({ type: "SET_IS_MUTED" });
    pauseAmbience();
  };
  const unsetIsMuted = () => {
    dispatch({ type: "UNSET_IS_MUTED" });
    playAmbience();
  };

  const toggleIsMuted = () => {
    if (state.isMuted) {
      dispatch({ type: "UNSET_IS_MUTED" });
      playAmbience();
    } else {
      dispatch({ type: "SET_IS_MUTED" });
      pauseAmbience();
    }
  };

  const setAmbience = (value: HTMLAudioElement) => {
    value.addEventListener("ended", function () {
      //loop
      this.currentTime = 0;
      this.play();
    });

    dispatch({ type: "SET_AMBIENCE", value });
  };

  const playAmbience = () => {
    if (!state.ambience) {
      const freshAmbience = new Audio(state.ambienceFiles[0]);
      dispatch({ type: "SET_AMBIENCE", value: freshAmbience });
      freshAmbience.play();
    } else {
      state.ambience.play();
    }
  };
  const pauseAmbience = () => {
    if (state.ambience && !state.ambience.paused) {
      state.ambience.pause();
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      setIsMuted,
      unsetIsMuted,
      toggleIsMuted,
      setAmbience,
      playAmbience,
      pauseAmbience,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useAudio = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useAudio must be used within a UIProvider`);
  }
  return context;
};

export const AudioContext: FC<AudioState> = ({ children }) => (
  <UIProvider>{children}</UIProvider>
);
