/* eslint-disable no-unused-vars */
import SoundButton from "components/SoundButton.jsx"
import SideMenu from "components/SideMenu"
import Hamburger from "components/Hamburger.tsx"

import { useScrolledPast } from "lib/hooks/useScroll"
import { useMenu } from "menu/context"
const MainLayout = ({ children }) => {
  let hasScrolledPast = useScrolledPast("#introsection")

  const { menuIsOpen } = useMenu()
  const backgroundImageUrl = process.env.PUBLIC_URL + "/bg1.jpeg"
  const filter = menuIsOpen ? "blur(5px)" : ""
  return (
    <>
      {/* {hasScrolledPast && <Hamburger />} */}
      {/* <SideMenu /> */}
      <main style={{ filter: filter }}>
        <div className="backgrounds overlay">
          <div
            className="background"
            data-aos="fade-in"
            data-aos-duration="1500"
            data-aos-anchor=".section--hero"
            style={{
              backgroundImage: `url(${backgroundImageUrl})`
            }}
          ></div>
        </div>
        <SoundButton />
        {children}
      </main>
    </>
  )
}

export default MainLayout
