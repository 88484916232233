import React, { FC, useMemo } from "react";

export interface MenuState {
  menuIsOpen: boolean;
}

const initialState = {
  menuIsOpen: false,
};

type Action =
  | {
      type: "OPEN_MENU";
    }
  | {
      type: "CLOSE_MENU";
    };

export const UIContext = React.createContext<MenuState | any>(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: MenuState, action: Action) {
  switch (action.type) {
    case "OPEN_MENU": {
      return {
        ...state,
        menuIsOpen: true,
      };
    }
    case "CLOSE_MENU": {
      return {
        ...state,
        menuIsOpen: false,
      };
    }
  }
}

export const UIProvider: FC = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const openMenu = () => {
    dispatch({ type: "OPEN_MENU" });
  };
  const closeMenu = () => {
    dispatch({ type: "CLOSE_MENU" });
  };

  const toggleMenu = () => {
    if (state.menuIsOpen) {
      dispatch({ type: "CLOSE_MENU" });
    } else {
      dispatch({ type: "OPEN_MENU" });
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      openMenu,
      closeMenu,
      toggleMenu,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return <UIContext.Provider value={value} {...props} />;
};

export const useMenu = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useMenu must be used within a UIProvider`);
  }
  return context;
};

export const MenuContext: FC<MenuState> = ({ children }) => (
  <UIProvider>{children}</UIProvider>
);
